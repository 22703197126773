<template>
  <v-expansion-panels popout multiple :readonly="$vuetify.breakpoint.mdAndUp" v-model="panels" focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="subtitle-1 font-weight-medium primary--text">1.- Sanciones</div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
          <Sanciones />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import Sanciones from "@/components/Sancion"
export default {
  components:{Sanciones},
  data() {
    return {
      panels:[0]
    };
  },
  
};
</script>