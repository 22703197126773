<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        item-key="id"
        :server-items-length="totalDesserts"
        :hide-default-footer="hiddenFoter"
        no-data-text="No registra ninguna Sancion"
        :mobile-breakpoint="100"
        :loading="loadingTable"
        calculate-widths
      >
        <template
            v-slot:item.acciones="{item}"
        >
          <DescargarResolucion 
            v-if="item.archivoSancion"
            :id="item.id"
            :url="`${$apiUrl}abogado-ciudadano/sanciones/${item.id}/resolucion`"
          />
        <v-tooltip bottom  v-if="item.conCodigoPago">
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              text 
              color="primary"
              icon
              @click="getMetodoPago(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
              mdi-cash
              </v-icon>
            </v-btn>
          </template>
          <span>Ver Codigo Pago</span>
        </v-tooltip>
        </template>

        <template
            v-slot:item.fechaSancion="{item}"
        >
          {{formatDateText(item.fechaSancion)}}
        </template>
        <template
            v-slot:item.fechaNotificacion="{item}"
        >
          {{formatDateText(item.fechaNotificacion)}}
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog" 
        persistent
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <v-toolbar color="secondary" dark>
            Información de pago de la sanción
          <v-spacer></v-spacer>
          <v-btn icon @click="cerrar"><v-icon>mdi-close</v-icon></v-btn>
         </v-toolbar>    
          <v-card-text class="pt-5">
            <v-row v-if="!loading">
              <v-col v-if="metodoPago.solicitudPasarelaPago" class="mt-3"  cols="12">
                <v-alert color="primary" outlined :value="true" text class="body-2" v-if="['CREADO','SOLICITADO','EN_PROCESO'].includes(metodoPago.solicitudPasarelaPago.estado)">
                  Usted tiene pendiente una solicitud de pago mediante la <b>PASARELA DE PAGOS</b>, para continuar debe 
                  <span v-if="metodoPago.solicitudPasarelaPago.metodoPago"> realizar el pago mediante <b>{{metodoPago.solicitudPasarelaPago.metodoPago}}</b> que se muestra a continuacion:</span>
                  <span v-else><v-btn color="primary" rounded small @click="getUrlRedireccion(metodoPago.solicitudPasarelaPago.id)">presionar aquí</v-btn> para realizar el pago (código transacción: {{metodoPago.solicitudPasarelaPago.codigoTransaccion}})</span>
                </v-alert>
              </v-col>
              <v-col cols="12" v-if="metodoPago.qr!==null">
                <qr-pago 
                    v-if="metodoPago.qr!== null" 
                    :qr="metodoPago.qr" 
                    :center="true"
                    :vertical="true"
                  />
              </v-col>
              <v-col cols="12" v-if="metodoPago.cpt!==null" >
              <codigo-pago
                    :cpt="metodoPago.cpt" 
                    :center="true"
                  />
              </v-col>
            </v-row>
            <v-row v-if="loading">
              <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate size="50" color="primary" ></v-progress-circular>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import Mabogado from "@/mixins/abogado";
import mixinDateTime from "@/mixins/datetime"
export default {
  mixins: [Mabogado,mixinDateTime],
  components:{
    'codigo-pago':()=>import("@/components/Cpt"),
    'qr-pago':()=>import("@/components/QrPago"),
    DescargarResolucion:()=>import("@/components/DescargarArchivo")
  },
  data() {
    return {
      headers: [
        {
          text: "Acciones",
          align: "left",
          value: "acciones",
          sortable: false
        },
         {
          text: "Tipo",
          align: "left",
          value: "tipo",
          sortable: false
        },
        {
          text: "Autoridad",
          align: "left",
          value: "cargoAutoridadSancionante",
          sortable: false
        },
        {
          text: "Fecha Sancion",
          align: "left",
          value: "fechaSancion",
          sortable: false
        },
         {
          text: "Fecha Notificacion",
          align: "left",
          value: "fechaNotificacion",
          sortable: false
        },
        {
          text: "Descripcion",
          align: "left",
          value: "detalle",
          sortable: false
        }
      ],
      loadingTable:true,
      desserts:[],
      hiddenFoter: true,
      metodoPago:{
        solicitudPasarelaPago:null,
        cpt:null,
        qr:null
      },
      dialog:false,
      loading:false
    };
  },
  mounted() {
    //this.getExperienciaAbogado();
  },
  async created(){
    await this.getSancionesAbogado();
  },
  computed: {
    totalDesserts() {
      if (this.desserts.length > 8) {
        this.hiddenFoter = false;
      }
      return this.desserts.length;
    }    
  },
  methods:{
    getCPT(sancion){
      this.dialog = true
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/sanciones/${sancion.id}/codigo-pago-tramite`)
       .then(response=>{
              this.resetMetodoPago()
              this.metodoPago.cpt = response.data.data       
        }).catch(error=>{
        console.log('------------------------------------');
        console.log(error);
        console.log('------------------------------------');
        })
    },
    getMetodoPago(sancion){
      this.loading=true
      this.dialog = true
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/sanciones/${sancion.id}/metodo-pago`)
       .then(response=>{
          const {data} = response.data
          this.metodoPago.solicitudPasarelaPago= data.solicitudPasarelaPago || null
          this.metodoPago.qr= data.qr || null
          this.metodoPago.cpt= data.cpt || null
          this.loading=false
        }).catch(error=>{
          this.loading=false
        console.log('------------------------------------');
        console.log(error);
        console.log('------------------------------------');
        })
    },
    cerrar(){
      this.dialog = false
      this.resetMetodoPago()
    },
    resetMetodoPago(){
      this.metodoPago={
        solicitudPasarelaPago:null,
        cpt:null,
        qr:null
      }
    },
    getUrlRedireccion(id){
       this.$http.get(`${this.$apiUrl}abogado-ciudadano/solicitud-pasarela-pago/${id}/url-redireccion`).then(response=>{
        const {data, error} = response.data
        if(data){
          if(!error && data.estado === 'SOLICITADO' ){
            if(data.codigoTransaccion){
              this.$storage.set('codigo_transaccion',data.codigoTransaccion)
            }
            this.goUrl(data.urlRedireccion)
            return
          }
          if(!error && data.estado === 'EN_PROCESO' ){
            if(data.qr){
              this.metodoPago.qr = null
              const e = data.qr
              this.metodoPago.qr = {
                  id: e.id, 
                  idQr: e.id_qr,
                  codigoQr: e.codigo_qr||null,
                  monto: e.monto,
                  estado: e.estado_codigo.nombre,
                  tipoTramite: "CREDENCIAL",
                  fechaCreacion: e.fecha_creacion,
                  activo: true
              }
            }else{
              this.metodoPago.cpt = null
               const e = data.cpt
              this.metodoPago.cpt = {
                  id: e.id, 
                  codigo: e.codigo_cpt,
                  fechaInicio: e.fecha_inicio,
                  fechaVencimiento: e.fecha_vencimiento,
                  estado: e.estado_codigo.nombre,
                  monto: e.monto
              }
            }
          }
        }
       })
    },
    goUrl(url){
      window.location.href = url
    }
  }
};
</script>
